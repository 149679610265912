<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">考试公告管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">{{ pageDatas.examinationId ? '编辑' : '新建' }}</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form ref="pageDatas" label-width="100px" class="demo-ruleForm form-box" size="small"
              :rules="pageDatasRules" :model="pageDatas">
              <el-form-item label="公告名称" prop="noticeTitle">
                <el-input v-model="pageDatas.noticeTitle" placeholder="请输入公告名称"></el-input>
              </el-form-item>
              <el-form-item label="公告封面：" prop="qrCode" class="form-item"><el-upload :on-change="handleAvatarSuccess5"
                  :before-upload="beforeAvatarUpload2" :http-request="$requestMine" :show-file-list="false"
                  class="img-el-upload" action accept="image/png, image/gif, image/jpg, image/jpeg"><el-image
                    :src="pageDatas.noticeCoverUrl || require('@/assets/develop.png')" fit="contain" class="imgCenter"></el-image>
                </el-upload>
              </el-form-item>
              <el-form-item label="公告内容" prop="noticeContent">
                <el-input type="textarea" rows="8" resize="none" style="width: 100%; max-width: 500px" maxlength="1000" show-word-limit placeholder="请输入公告内容" v-model="pageDatas.noticeContent"></el-input>
                <!-- <div ref="editor" class="editor" style="width: 100%; max-width: 1200px"></div> -->
              </el-form-item>
            </el-form>
            <div class="btn-box flexcc">
              <el-button class="bgc-bv" size="small" @click="cancelSave(2)">取 消</el-button>
              <el-button class="bgc-bv" size="small" @click="cancelSave(1)">确 定</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import E from "wangeditor";
let editor = {};
export default {
  name: "examination_onlineTest_announcementOfManagementAddEdit",
  components: {},
  data() {
    return {
      // 页面数据
      pageDatas: {
        noticeId: "", // 考试公告id
        noticeTitle: "", // 公告名称
        noticeCover: "", // 公告封面key
        noticeCoverUrl: "", // 公告封面url
        noticeContent: "", // 公告内容
      },
      // 页面数据 - 数据校验
      pageDatasRules: {
        noticeTitle: [
          { required: true, trigger: "blur", message: "请输入公告名称" },
        ],
        noticeCover: [
          { required: true, trigger: "change", message: "请选择公告封面" },
        ],
        noticeContent: [
          { required: true, trigger: "change", message: "请输入公告名称" },
        ],
      },
    };
  },
  created() {
    this.pageDatas.noticeId = this.$route.query?.noticeId ?? "";
    if (this.$route.query?.noticeId) {
      this.getOnlineTestInfo();
    }
  },
  watch: {},
  computed: {},
  mounted() {
    // this.editorInit();
  },
  methods: {
    // 获取 - 考试详情
    getOnlineTestInfo() {
      this.$post("/biz/examination/notice/detail", { noticeId: this.pageDatas.noticeId }, 3000, true, 6)
        .then((res) => {
          this.pageDatas = {
            ...this.pageDatas,
            ...res.data
          }
          // editor.txt.html(res.data?.noticeContent ?? '');
        })
        .catch((err) => {
          return;
        });
    },
    // 上传封面图
    handleAvatarSuccess5(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "MATERIALS");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((res) => {
            console.log(res)
            this.pageDatas.noticeCover = res.data.fileKey;
            this.pageDatas.noticeCoverUrl = res.data.fileURL;
          })
          .catch((err) => {
            console.log(err)
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    // 富文本
    editorInit() {
      editor = new E(this.$refs.editor);
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo", // 重复
      ];
      editor.config.zIndex = 100;
      editor.config.uploadImgMaxSize = 5 * 1024 * 1024;
      editor.config.pasteIgnoreImg = true;
      editor.config.pasteFilterStyle = false;
      editor.config.customUploadImg = (files, insert) => {
        let extension = files[0].name.substring(
          files[0].name.lastIndexOf(".") + 1,
          files[0].name.length
        );
        let formData = new FormData();
        formData.append("file ", files[0]);
        formData.append("fileType ", extension);
        formData.append("folder ", "LECTURER");
        formData.append("isPublic ", true);
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            insert(result.data.fileURL);
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      };

      editor.config.onchange = (html) => {
        this.pageDatas.noticeContent = html;
      };
      editor.config.pasteTextHandle = function (content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editor.create();
    },
    // 取消&保存
    cancelSave(cType) {
      if (cType == 1) {
        this.$refs['pageDatas'].validate((valid) => {
          if (valid) {
            let url = '';
            if (this.pageDatas.noticeId) {
              url = '/biz/examination/notice/update'
            } else {
              url = '/biz/examination/notice/insert'
            }
            let cData = { ...this.pageDatas };
            this.$post(url, cData, 3000, true, 6)
              .then((res) => {
                this.$message({
                  type: "success",
                  message: res.message,
                });
                this.$router.back();
              })
              .catch((err) => {
                return;
              });
          }
        });
      } else {
        this.$router.back();
      }
    }
  },
};
</script>
<style lang="less" scoped>
.form-box {
  .el-input {
    width: 300px;
  }
}
</style>
  